import { Injectable, signal } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  headerText$: Observable<string>;
  navigationBackLink = signal<{ label: string, link: string }>({ label: '', link: '' });
  private _headerText$ = new BehaviorSubject<string>("");

  constructor() {
    this.headerText$ = this._headerText$.asObservable();
  }

  setHeaderText(name: string) {
    this._headerText$.next(name);
  }

  resetNavigationBackLink() {
    this.navigationBackLink.set({ label: '', link: '' });
  }
}
